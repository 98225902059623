@import "Assets/styles/variables";

.StaticContentModal-component {
    z-index: 11;
    font-size: 18px;

    .modal-dialog {
        max-height: 90%;
        margin: 5% auto 0;
    }

    .modal-content {
        background-color: $white;
        border-radius: 20px;
        color: $black;

        p {
            text-align: left;
            font-size: 1em;
            font-weight: 600;
            line-height: 1.25;
            color: $black;
        }
    }

    .modal-header {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        justify-content: flex-end;
        border-bottom: 1px solid $themeBg;

        .btn {
            color: $black;
        }
    }

    .modal-footer {
        justify-content: flex-end;
        padding: 10px 25px;
        .btn {
            padding: 0 35px;
            height: 50px;
            width: auto;
            font-size: 1.25em;
            color: $white;
            background-color: $themeBlue !important;
        }
    }
}
