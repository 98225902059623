@import "Assets/styles/variables";

.InactiveModal-component {
    .modal.show .inactive-modal {
        display: block;
    }

    .modal-content {
        top: 50%;
        transform: translateY(-50%);
        box-shadow: 0 0 5px 5px rgba(0, 0, 0, .2);

        .modal-body {
            font-size: 1.2em;
            line-height: 1;
            font-weight: 600;
        }

        .modal-header {
            background-color: $white;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            justify-content: flex-end;
            border-bottom: 1px solid $themeBg;

            .btn {
                color: $black;
            }
        }

        .modal-footer .btn {
            font-size: 1.35rem;
            width: 40%;
        }
    }

    .modal-footer {
        justify-content: space-around;
    }
}
