@import "Assets/styles/variables";

.Search {
    display: flex;
    align-items: center;
    overflow: hidden;

    object {
        pointer-events: none;
    }

    form {
        display: flex;
        align-items: center;
        width: 100%;
        height: 65px;
        position: relative;
        overflow: hidden;
    }

    .search-control {
        position: relative;
        transform: translateX(320px);
        border: 0;
        outline: 0;
        padding: 9px 10px 9px 60px;
        height: 58px;
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        transition: transform .2s ease-in;
    }

    &.open .search-control {
        transform: translateX(0);
        transition: transform .2s ease-out;
    }

    .search-control::placeholder {
        font-style: italic;
        font-size: 15px;
    }

    &.open .close {
        transition: transform .2s ease-out;
        transform: translateX(0);
        opacity: 1;
        visibility: visible;
    }

    .close {
        position: absolute;
        z-index: 5;
        left: 15px;
        transition: transform .2s ease-in;
        transform: translateX(390px);
        opacity: 0;
        visibility: hidden;
        padding: 20px 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        appearance: none;
        border: 0;
        outline: 0;
        background-color: transparent;

        &:before {
            content: '';
            display: block;
            padding: 4px;
            border: solid #000;
            border-width: 0 2px 2px 0;
            transform: rotate(135deg);
        }

        &:after {
            position: relative;
            left: -9px;
            width: 20px;
            height: 2px;
            display: block;
            content: '';
            background-color: #000;
        }
    }

    .submit {
        .text {
            height: 20px;
            margin-top: 5px;
            opacity: 1;
            visibility: visible;
            transition: all 0.1s;
            transition-delay: 0.3s;
        }
        transition: height 0.2s, border-color 0.2s;
        transition-delay: 0.2s;
        z-index: 2;
        height: 70px;
        padding: 0 12px;
        margin: 0;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        border: 3px solid transparent;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        appearance: none;
        background-color: transparent;
        outline: 0 none;
        color: $white;
        font-size: 17px;
        line-height: 20px;
        font-weight: 500;
    }

    &.open .submit {
        .text {
            height: 0;
            margin-top: 0;
            transition: all 0.1s;
            transition-delay: 0s;
            opacity: 0;
            visibility: hidden;
        }
        transition: height 0.25s, border-color 0.2s;
        height: 58px;
        transition-delay: 0.1s;
        border-color: #FFF;

        svg {
            width: 20px;
        }
    }
}
