@import "Assets/styles/variables";

.Header {
    &.back--visible {
        .search-control {
            //width: 270px;
        }

        .header-link {
            margin: 0 5px;
        }
    }

    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 112px;
    width: 100vw;
    border-bottom: 2px solid #E13638;
    padding: 0 15px !important;
    background-color: $themeRed;
    z-index: 10;

    > div {
        display: flex;
        align-items: center;
    }

    .logo {
        height: 65px;
    }

    .go-back {
        svg {
            width: 10px;
            transform: scale(1.3);
        }

        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        background-color: transparent;
        outline: 0;
        border: 0;
        border-right: 2px solid $white;
        border-radius: 2px;
        height: 60px;
        padding: 0 20px 0 10px;
        margin-right: 15px;
        color: $white;
        fill: $white;
        font-size: 1.45em;
        font-weight: 600;
        line-height: 20px;
        text-align: center;
    }

    .header-link {
        object {
            pointer-events: none;
        }

        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 65px;
        color: $white;
        margin: 0 15px;
        font-size: 1em;
        line-height: 20px;
        font-weight: 500;
        text-decoration: none;
    }

    .header-link.cart {
        position: relative;
        border-left: 2px solid $white;
        padding-left: 15px;

        .items {
            position: absolute;
            top: -10px;
            right: 10px;
            width: 38px;
            height: 38px;
            border: 2px solid $white;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $themeBlue;
            color: #FFF;
            font-size: 16px;
            line-height: 1;
            font-weight: 600;
            text-align: center;
        }
    }

    svg {
        fill: $white;
    }
}
