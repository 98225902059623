@import "Assets/styles/variables";

.Wizard-component {
    z-index: 11;
    font-size: 18px;

    .modal-header {
        font-size: 1.5em;
        font-weight: 600;
        display: flex;
        justify-content: center !important;
        padding: 25px 0;
    }

    .footer {
        width: 100vw;
        height: 65px;
        position: fixed;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9;
        background-color: $themeGray;
    }

    .btn {
        background-color: transparent !important;
        font-size: 1.22em;
        box-shadow: none;
        color: $white;
    }

    p {
        margin: 0 0 0 10px;
        font-size: 1em;
        line-height: 1.1;
        font-weight: 700;
    }

    .faq-extra-content {
        position: absolute;
        bottom: 1em;
        right: 0;

        .btn-link {
            color: $linkColor;
            font-size: 1.2rem;
            font-weight: 500;
        }
    }
}
