@import "Assets/styles/variables";

.Error {
    .Header {
        display: flex !important;
        position: static !important;
        background-color: $themeRed !important;
    }

    .Wizard-component {
        display: none;
    }

    .container {
        height: calc(100vh - 112px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        top: 0;
    }

    .title {
        font-size: 3.3em !important;
        font-weight: 800 !important;
        color: $themeRed !important;
    }

    .description {
        margin: 10px 0 35px !important;
        p {
            margin: 0;
            font-size: 2.27em;
            font-weight: 600;
            line-height: 1.3;
        }
    }

    .btn {
        margin: 0 auto;
        font-size: 1.15em;
        padding: 10px 35px;
        height: 54px;
    }
}
