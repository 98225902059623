@import "Assets/styles/variables";

.ErrorMessage {
  display: block;
  padding: 1em;

  .error {
    color: $themeRed;
    font-size: 1.35rem;
    font-weight: 500;
  }
}
