@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap");
@import "variables";

html {
    touch-action: none;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, opera and ff */
}

::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

body {
    margin: 0;
    background-color: $themeBg;
    font-size: 18px;
    line-height: 1;
    overflow: scroll;
    overflow-x: hidden;
    font-family: $fontFamily;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.App {
    text-align: center;
    height: 100%;
    width: 100vw;
    min-width: 100%;
    min-height: calc(100vh - 112px);
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;

    main {
        position: relative;
        margin-top: 112px;
        padding: 2rem 0;
        flex-grow: 1;
    }

    main section {
        margin: 2em 0;
    }

    h1 {
        font-weight: 800;
    }

    .section-title {
        font-size: 26px;
        font-weight: 800;
        line-Height: 32px;
        color: $black;
        text-align: left;
        display: block;
    }

    .bold {
        font-weight: 700;
    }

    .extra-bold {
        font-weight: 800;
    }

    .red-text {
        color: $themeRed;
    }

    .link-color {
        color: $linkColor;
    }

    .link {
        color: $linkColor;
        border-bottom: 1px solid #6a55f3;

        &:hover {
            text-decoration: none;
            color: $darkLinkColor;
        }

        &.link-danger {
            color: $themeRed;

            &:hover {
                color: $darkLinkColor;
            }
        }
    }

    .box {
        color: $black;
        background-color: $white;
        border-radius: 20px;
        padding: 3em 0 1em;
        box-shadow: rgba($black, 0.16) 0 3px 6px;

        .box-footer {
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 1em 2em;
            min-height: 50px;

            .btn {
                font-size: 1.5rem;
                padding: 15px 25px;
            }
        }
    }

    .page-title {
        font-size: 3rem;
        line-height: 1;
        font-weight: 800;
        margin-bottom: 15px;
    }

    hr {
        border-top: 4px solid $themeBg
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

a {
    &:hover, &:active {
        text-decoration: none;
    }
}

.btn {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    padding: 15px 30px;
    border: 0 none;
    border-radius: 50px;
    outline: 0 none;
    text-align: center;
    font-family: $fontFamily;
    font-size: 1.65em;
    line-height: 1.1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &:hover {
        text-decoration: none;
    }

    &.right-arrow:after, &.left-arrow:before, &.bottom-arrow:after, &.before-check:before {
        content: '';
        margin-left: 15px;
        transform: rotate(-45deg);
        border: solid;
        border-width: 0 5px 5px 0;
        border-top-right-radius: 3px;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        height: 13px;
        width: 13px;
    }

    &.left-arrow:before {
        margin-left: 0;
        margin-right: 15px;
        border-width: 5px 0 0 5px;
    }

    &.bottom-arrow:after {
        transform: rotate(45deg);
    }

    &.before-check:before {
        transform: rotate(45deg);
        margin-left: 0;
        margin-right: 15px;
        height: 18px;
        width: 12px;
        margin-top: -4px;
    }

    &.btn-primary {
        &:after {
            border-color: $black;
        }

        &.icon {
            svg {
                fill: $black;
                width: 30px;
                margin-right: 10px;

                .a {
                    fill: $black;
                }
            }

        }

        box-shadow: rgba($black, 0.15) 0 3px 6px;
        color: $black;
        background-color: $white;
        font-weight: 600;
        font-size: 1.45em;
        line-height: 1.65em;
    }

    &.btn-secondary {
        &:after {
            border-color: $white;
        }

        color: $white;
        background-color: $themeBlue;
        font-weight: 800;
    }

    &.btn-third {
        color: $white;
        background-color: $grayText;
        font-weight: 800;
    }

    &.btn-link {
        border: none;
        padding: 0;
        &:hover, &:focus {
            text-decoration: none;
            color: initial;
        }
    }

    &.btn-outline-third {
        border: 2px solid $grayText;
        color: $grayText;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
