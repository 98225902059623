@import "Assets/styles/variables";

.modal {
    font-size: 18px;

    .modal-dialog {
        max-width: none;
        padding: 0 60px;
    }

    .btn {
        font-size: 1.5em;
        width: auto;
        min-width: 270px;
        height: 60px;
    }

    .modal-header {
        border: 0;
        padding: 0 1rem;
        justify-content: flex-start;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom: 2px solid $themeBg;

        .btn {
            &:after {
                border-color: transparent;
                border-top-color: initial;
                border-bottom-color: initial;
                position: absolute;
                right: 15px;
                margin: 2px 0 0;
                transform: rotate(180deg);
            }
            position: relative;
        }

        .btn-secondary {
            width: auto;
            min-width: 0;
            padding: 0 25px;
            color: $white;
            background-color: transparent !important;
            font-weight: 500;
            font-size: 1.25em;

            span {
                margin-left: 6px;
                font-weight: 800;
            }
        }
    }

    .modal-content {
        //background-color: transparent;
        min-height: 300px;
        border: 0;
    }

    .modal-header, .modal-body, .modal-footer {
        //background-color: $white;
        box-shadow: none !important;
    }

    .modal-footer {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        justify-content: space-between;
        box-shadow: none !important;
        padding: 1rem;
        border-top: 2px solid $themeBg;

        .btn {
            font-size: 2rem;
            height: 71px;
            width: 330px;
        }
    }
}
