// Font
$fontFamily: Montserrat, sans-serif;
$fontSize: 18;

// Colors
$white: #ffffff;
$black: #000000;
$lightGray: #ccc;
$gray: #8A8A8A;
$grayText: #5B5B5B;
$linkColor: #6a55f3;
$lightLinkColor: #b5adea;
$darkLinkColor: #484094;
$red: #bd2130;
$darkRed: #b21f2d;
$highlight: #f5dbb4;
$themeBg: #F8F8F8;
$themeRed: #EE4041;
$themeLightRed: #FFB7B7;
$themeBlue: #0076EC;
$themeHighlight: #DEEEFF;
$themeGray: #4A4A4A;

// Mixins

@mixin pseudo-plus {
    content: '+';
    position: absolute;
    bottom: 20px;
    width: 40px;
    height: 40px;
    background-color: $themeRed;
    border-radius: 100%;
    color: $white;
    font-size: 35px;
    font-weight: 500;
    line-height: 40px;
}

@mixin price-reduced-after-line {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: $themeRed;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}
