@import "Assets/styles/variables";

.Loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($themeBg,0.15);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
        font-size: 20px;
        font-weight: 800;
        color: $white;
    }

    .spinner {
        width: 5em;
        height: 5em;
        color: $themeRed !important;
        position: absolute;
    }
}
